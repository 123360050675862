.header-wrap {
  height: 60px;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 5;

  .header {
    @include blur-component-bg(13);
    height: 60px;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;    
    //box-shadow: 0 0 34px rgba(0, 0, 0, .55);
    box-shadow: 0 0 21px 0 rgba(0, 0, 0, 0.08);

    /* NOTES: '& > div:first-child' is commented out due to an issue with react device detection. Once that is fixed, we can uncomment it -->
    & > div:first-child {
      max-height: 42px;
      margin-top: 8px;
      width: 61px;
      float: left;
      margin-left: 1rem;
      .logo {      
        max-height: 42px;               
        width: 61px;        
      }
    } */ 
    
    /* NOTES: .logo.short & .logo.full' were added due to an issue with react device detection. Once that is fixed, they should be removed --> */
    & > div:first-child {
      max-height: 42px;
      margin-top: 8px;
      width: 61px;
      float: left;
      margin-left: 1rem;
      
      .logo.short {
        max-height: 42px;               
        width: 61px;   
      }
  
      .logo.full {
        display: none;
      }

    }
    
  
    @include breakpoint(large) {
      height: 92px;
      /* NOTES: '& > div:first-child' is commented out due to issue with react device detection. Once that is fixed, we can uncomment it -->
      & > div:first-child {        
        width: 160px;
        margin-left: 2rem;
        margin-top: 1.1rem;
        .logo {
          max-height: 60px;
          width: 160px;        
        }
      } */

      /* NOTES: .logo.short & .logo.full' were added due to an issue with react device detection. Once that is fixed, they should be removed --> */
      & > div:first-child { 
        width: 160px;
        margin-left: 2rem;
        margin-top: 1.1rem;
        .logo.short {
          display: none;
        }    
        .logo.full {        
          max-height: 60px;
          width: 160px; 
          display: block;
        }
      }
      
    } // END lage

    .menu {
      font-size: 2.5rem;
      padding: .55rem .4rem;
      padding: .25rem .4rem .55rem;
      line-height: 1;
      color: $color-primary;
      margin-right: .6rem;
      float: right;
      cursor: pointer;

        @include breakpoint(large) {
          margin-right: 1rem;
          margin-top: .8rem;
        }
      } // END .menu        
  } // END .header 
} // END .header-wrap


// FIREFOX ONLY 
.browser-firefox {
  .header {
    background-color: rgba(255, 255, 255, 0.95);
  }
}