@mixin blur-component-bg($blur: 13, $opacity: 0.55) {
  background-color: rgba(255, 255, 255, $opacity);
  -webkit-backdrop-filter: blur($blur + px);
  backdrop-filter: blur($blur + px);
}

@mixin card-radius($tl: 12, $tr: 12, $br: 0, $bl: 0) {
  border-radius: $tl + px $tr + px $br + px $bl + px;
}

@mixin box-shadow($x: 0, $y: 0, $blur: 55, $opacity: .13) {
  -webkit-box-shadow: $x + px $y + px $blur + px 0px rgba(50, 50, 50, $opacity);
  -moz-box-shadow:    $x + px $y + px $blur + px 0px rgba(50, 50, 50, $opacity);
  box-shadow:         $x + px $y + px $blur + px 0px rgba(50, 50, 50, $opacity);
}

@mixin typography-base($letter-spacing: -1) {
  font-family: $font-family-base;
  font-size: 100%;
  line-height: 1.6;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  letter-spacing: $letter-spacing + px;
  color: $color-base;
}

@mixin close-icon($font-size: 1.7) {
  font-size: $font-size + rem;
  padding: 13px 13px;
  position: absolute;
  top: 0;
  right: 0;  
  //color: lighten($color-base, 40%); 
  opacity: .6;
}

@mixin input-text {
  @include typography-base(0);
  border-radius: 5px;
  background-color: $color-light;
  padding: 7px 15px 7px;
  margin: 0 0 .5rem;
  letter-spacing: 0px;
  color: $color-base;  
  -webkit-appearance: none;
  border: solid 1px lighten($color-base, 50%);
}

@mixin breakpoint($point) {
  //$bp-small: "screen and (max-width: 600px)";
  $bp-xsmall-only: "screen and (max-width: 20.3125emem)";
  $bp-small-only: "screen and (max-width: 39.9375em)";
  $bp-medium: "screen and (min-width: 40em)";
  $bp-medium-only: "screen and (min-width: 40em) and (max-width: 63.9375em)";
  $bp-large: "screen and (min-width: 64em)";
  $bp-large-only: "screen and (min-width: 64em) and (max-width: 74.9375em)";
  $bp-xlarge: "screen and (min-width: 70em)";
  
  @if $point == xsmall-only {
    @media #{$bp-xsmall-only} { @content; }
  }
  @if $point == small-only {
    @media #{$bp-small-only} { @content; }
  }
  @else if $point == medium {
    @media #{$bp-medium} { @content; }
  }
  @else if $point == medium-only {
    @media #{$bp-medium-only} { @content; }
  }
  @else if $point == large {
    @media #{$bp-large}  { @content; }
  }
  @else if $point == large-only {
    @media #{$bp-large-only}  { @content; }
  }
  @else if $point == xlarge {
    @media #{$bp-large-only}  { @content; }
  }
}