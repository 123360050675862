.map-search-card {
    @include card-radius;
    width: 100%;
    min-height: 45vh;
    max-height: 45vh;
    height: 46%;
    position: fixed;
    bottom: -50vh;
    left: 0;
    z-index: 14;
    -webkit-box-shadow: 0 0 21px 0 rgba(0,0,0,.13);
    box-shadow: 0 0 21px 0 rgba(0,0,0,.13);
    background: $color-light;
    transition: bottom 340ms ease-out, left 340ms ease-out;    

    .close {
        @include close-icon;
      }

    &.open, &.active {
        bottom: 0;
    }  

    header h2 {
        margin: 1.2rem 1rem 0 1.1rem;
        text-align: left;
        text-transform: uppercase;
        letter-spacing: 1px;
        font-size: .8rem;
        color: lighten($color-base, 21%);
    }

    .search-container {
        margin-top: 30px; 

        .search-inputs {
            position: relative;

            /*.text-field {
                @include box-shadow;
                padding-right: 50px;
                width: 68%;
            }*/
            .button-submit {
                position: absolute;
                right: 7%;
                font-size: 1.5rem;
                padding: 10px;
                color: $color-accent;
                bottom: 0;
                top: -2px;
                display: none;
            }

            .text-input-container{
                input{
                    @include box-shadow;                    
                    @include input-text;
                    width: 83%;
                    margin-bottom: 0;
                }
            }
        }
    }
    .get-position-container {
            margin-top: 10px;

            .trigger-get-position {
                width: 85%;
                text-align: right;
                display: block;
                margin: 0 auto;
                padding: 7px 15px 7px;
                color: $color-accent;
                cursor: pointer;

                .acp {
                    margin-right: 5px;
                }
            }
    }
}

.search-autocomplete-menu {    
    z-index: 20;
    width: 95% !important;
    left: 1% !important;
    z-index: 20;
    top: -2px !important;
    
    @include breakpoint(large) {
        width: 340px !important;
        left: 18px !important;
    }

}

// DESKTOP 
 @include breakpoint(large) {
    .map-search-card {
        @include card-radius(12, 12, 12, 12);
        bottom: auto;
        top: 210px;            
        left: -100%;
        max-width: 375px;
        min-height: 180px;
            height: 180px;
    }

    // .footer-tray located here b/c the only reason there are transitions and changes in height is due to the desktop view of the search card. 
    .footer-tray {
        transition: top 210ms ease-out, height 340ms ease-out;
    }

    .footer-wrap.search-open {
        .map-search-card {
            left: 1rem;
        }

        .footer-tray {
            top: 405px;
            height: 45vh;
        }
    }
}

.MuiAutocomplete-option{
    font-size: 14px;
}