.footer-wrap {
  width: 100%;
  height: auto;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 10; 

  @include breakpoint(large) {
    position: static;
    bottom: auto;
    left: auto;
  } // END lage
  
  
  .footer-nav {
    @include blur-component-bg;
    width: 100%;
    height: $footer-nav-height - 15px;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 15; 
            box-shadow: 0 0 21px 5px rgba(56, 57, 58, 0.15);
    -webkit-box-shadow: 0 0 21px 5px rgba(56, 57, 58, 0.15);


    @include breakpoint(large) {
      height: $footer-nav-height;
      @include card-radius(12, 12, 12, 12);
      top: 110px;
      left: 1rem;
      width: 375px;
     } // END large

    nav {      
      display: flex;
      justify-content: space-around;
      align-items: center;
      height: 100%;

      .nav-item {
        color: $color-primary;
        text-decoration: none;
        display: flex;
        flex-flow: column wrap;
        margin-top: -2px; 
        transition: color 340ms ease;

        @include breakpoint(large) {
          margin-top: -1px;
          margin-left: -15px;
         } // END lage

        .nav-item-icon {
          display: block;
          font-size: 2.1rem;
          margin: auto;
          padding: .2rem 1rem 0;
          font-weight: 600;
          line-height: 2rem;
          
          &.acp-wifi {
            font-size: 3rem;
            line-height: 2rem;
          }
          &.acp-heart {
            font-size: 1.8rem;
          }
        } // END .nav-item-icon
        .nav-item-title {
          margin: auto;
          display: block;
          letter-spacing: -1px;
          font-weight: bold;
          font-size: 1.1rem;
        }

      } // END .nav-item
    } // END nav
  } // END .footer-nav

  .footer-tray {
    @include blur-component-bg;
    @include card-radius;
    height: $footer-nav-height + 25px;        
    padding: 0;
    transition: all .4s ease;
    z-index: 4;
    /* z-index: 0; */
    /* position: absolute; */
    /* bottom: 0; */
    /* left: 0; */
    width: 100%;
    -webkit-box-shadow: 0 0 21px 0 rgba(0,0,0,.13);
    box-shadow: 0 0 21px 0 rgba(0,0,0,.13);
    

    .tray-trigger {
      width: 100%;
      padding-bottom: 10px;

      @include breakpoint(large) {
        display: none;
      } // END lage
    }

    .grab-lines {
      margin-top: 14px;
      font-size: .8rem;
      //color: lighten($color-base, 50%)
      opacity: .4;
    }
    
    &.open {
      height: 45vh;
     
    }

    .tray-overflow {
      width: 100%;
      height: 100%;
      overflow-y: scroll; 
      
      @include breakpoint(large) {
        height: calc(96.5% - 20px);     
        margin-top: 12px;
        margin-bottom: 12px;
        overflow-y: auto;
      } // END lage
    } // END .tray-overflow

    .location-menu-item-row:last-child {
          margin-bottom: 140px;
    }    

    @include breakpoint(large) {
      @include card-radius(12, 12, 12, 12);
      position: absolute;
      top: 210px;
      left: 1rem;
      width: 375px;
      height: 70vh;
    } // END lage    
  } // END .footer-tray

  &.favorites-open .nav-item.favorites,
  &.search-open .nav-item.search {
    //color: lighten($color-base, 21%);
    color: $color-base;
  }
}


// SAFARI ONLY 
.browser-safari, .browser-mobile-safari {
  .footer-wrap {   
    @include card-radius;
    @include breakpoint(large) {
      @include card-radius(12, 12, 12, 12);
     } // END large
   
  }
  .footer-nav nav {
    @include breakpoint(large) {
      @include card-radius(12, 12, 12, 12);
     } // END large
  }
  .tray-trigger {
    -webkit-backdrop-filter: blur(13px);
    @include card-radius;
  }
}

// FIREFOX ONLY 
.browser-firefox {
  .footer-nav, .footer-tray  {
    background-color: rgba(255, 255, 255, 0.95);
  }
}
