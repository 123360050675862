.map-wrap {
  width: 100%;
  height: 100%;

  .mapboxgl-control-container {
    bottom: 1rem;
    right: 1rem;
    position: absolute;
    width: 177px;
    text-align: right;
    
    .mapboxgl-ctrl-group:not(:empty) {
      @include blur-component-bg;
      box-shadow: 0 0 13px 0 rgba(0,0,0,.13);
    }
  }

  .map-zooom-controls {
    position: fixed !important;
    right: 1rem !important;
    top: 85px !important;
    box-shadow: 0 0 21px 5px rgba(56, 57, 58, 0.15) !important;
    -webkit-box-shadow: 0 0 21px 5px rgba(56, 57, 58, 0.15) !important;
    border: none !important;

    button {
      @include blur-component-bg;
    }

    @include breakpoint(large) {
      top: 110px !important;
    }
  }
}

.map-container {
  width: 100%;
  height: 100%;
}

.sidebarStyle {
  position: absolute;
    top: 60px;
    left: 0;
    background-color: rgba(0,0,0,.13);
    color: #fff;
    z-index: 1;
    padding: 6px 0;
    font-weight: bold;
    width: 100%;
    text-align: center;
    display: none;;
}

.mapboxgl-popup-content {
  background-color: #ffffff;
  opacity: 0.8;

}
.mapboxgl-popup-content table {
  margin-left:auto;
  margin-right:auto;
}


.search-input {
  display: inline-block;
    position: absolute;
    top: 59px;
    left: 0;
    z-index: 1;
    padding: 3%;
    width: 94%;
    overflow: hidden;
    backdrop-filter: blur(8px);
    @include breakpoint(large) {
      left: auto;
      right: 0;
      width: 50%;
    } // END lage
}


.map-marker {
  cursor: pointer;
  &.acp {
    font-size: 2.8125rem;
    &:before {
      position: relative;
      z-index: 1;
    }
    &:after {
      content: ' ';
      box-shadow: 0 0 8px 3px rgba(103, 115, 72, .55);
      width: 15px;
      height: 1px;
      display: block;
      position: relative;
      border-radius: 50%;
      left: 15px;
      bottom: 4px;
      position: relative;
      z-index: 0;
    }
  }
}

.map-no-locations-container {
  h5 {
    margin: 1rem auto 0;
    line-height: 1.44;
  }

  p {
    margin: .5rem auto;
    max-width: 300px;
  }
}