.map-favorites-card {
    @include card-radius;
    width: 100%;
    min-height: 54vh;
    max-height: 54vh;
    height: 54%;
    position: fixed;
    bottom: -54vh;    
    left: 0;
    z-index: 14;
    -webkit-box-shadow: 0 0 21px 0 rgba(0,0,0,.13);
    box-shadow: 0 0 21px 0 rgba(0,0,0,.13);
    background: $color-light;
    transition: bottom 340ms ease-out, left 340ms ease-out;    

    .close {
        @include close-icon;
      }

    &.open, &.active {
        bottom: 0;
    }  

    header h2 {
        margin: 1.2rem 1rem 0 1.1rem;
        text-align: left;
        text-transform: uppercase;
        letter-spacing: 1px;
        font-size: .8rem;
        color: lighten($color-base, 21%);
    }

    .favorites-container {
        margin-top: 5px; 
        height: 100%;

        .location-menu-item-row:last-child {
            margin-bottom: 140px;
      }  
        
    } // END .favorites-container
   
}


// DESKTOP 
 @include breakpoint(large) {
    .map-favorites-card {
        @include card-radius(12, 12, 12, 12);
        bottom: auto;
        top: 210px;            
        left: -100%;
        max-width: 375px;
        min-height: 400px;
        height: 400px;
        max-height: 400px;
    }

    .footer-wrap.favorites-open {
        .map-favorites-card {
            left: 1rem;

            .favorites-container { 
                height: 335px;
        
                .location-menu-item-row:last-child {
                    margin-bottom: 0;
                }
            }
        } // END map-favorites-card

        .footer-tray {
            top: 625px;
            height: 20vh;
        }
    }
}