.content-container {
  @include card-radius(12, 12, 12, 12);
  @include box-shadow;
  min-height: 400px;
  height: auto;
  max-width: 900px;
  background: $color-light;
  position: relative;
  top: 72px;
  margin: 0 15px 72px; 
  padding: 15px 0 30px; 

  @include breakpoint(medium) {
    margin-bottom: 90px;
  }

  @include breakpoint(large) {
    margin: 0 auto 120px;
    top: 120px;
  }

  header {
    padding: 5px 25px 15px;

    @include breakpoint(medium) {
       padding: 30px 40px 15px;
    }
    
    @include breakpoint(large) {
      
    }

    h1 {
      line-height: 1.3;
      max-width: 800px;      
      margin: 0 auto;
      padding-top: 20px;
    }

    p {
      max-width: 650px;
      margin: 0 auto 30px;
    }

    .hero {      
      margin: 0 auto 20px;
      width: 100%;
      height: 250px;
      background-size: cover;
      background-repeat:  no-repeat;
      background-position: center 30%;

      @include breakpoint(medium) {
        width: 100%;
        height: 500px;
      }
    
      @include breakpoint(large) {
        width: 800px;
        height: 500px;
      }

      &.about-us {
        background-image: url('../../assets/images/hero-about-acp-800x640.jpg');

        @include breakpoint(medium) {
          background-image: url('../../assets/images/hero-about-acp-800x640.jpg');
        }
      
        @include breakpoint(large) {
          background-image: url('../../assets/images/hero-about-acp-800x640.jpg');
        }
      }
    }
    
    .logo {
      margin: 0 auto 15px;
      max-width: 250px;
    }
  }

  .content-body {
    padding: 0 25px 40px;
    text-align: left;

    @include breakpoint(medium) {
      padding: 0 40px 40px;
    }

    img {
      margin: 20px;
      width: 100%;
      height: auto;
      max-width: 600px;
      max-height: 600px;
      
      &.radius {
        @include card-radius(12, 12, 12, 12);
      }
    }
  }
  
  
} // END .content-container