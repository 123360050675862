footer.footer-content-container {
  max-width: 897px;  
  margin: 40px auto 50px; 
  padding: 20px 15px 40px;   

  a {
    color: lighten($color-base, 30%);    
    text-decoration: underline;
  }

  p {
    color: lighten($color-base, 30%);
    font-size: .7rem;
    margin: 0 auto;
    //text-align: center;
  }
  
  .copyright, .legal {
     text-align: center;
  }

  .powered-by {
    margin-bottom: 1rem;
    letter-spacing: 0;
    font-weight: bold;
    text-align: center;
     a {
       text-decoration: none;
     }

     .logo {
       margin: 4px auto 0;
      font-size: 1.2rem;
      font-weight: normal;
      display: block;
    }
  }

  @include breakpoint(large) {
    .copyright, .legal {
      text-align: right;
    }

    .powered-by {      
      text-align: left;       
    }

    .footer-left {
      float: left;
    }
    .footer-right {
      float: right;
    }

   } // END large


}