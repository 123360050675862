.icon-container {
    height: 41px;
    width: 41px;
    border-radius: 5px;
    float: left;
    padding-left: 2px;

    &.green, &.on{
        background-color: $color-on;
    }

    &.red, &.off{
        background-color: $color-off;
    }

    &.gray, &.unknown{
        background-color: $color-base;
    }

    .acp {
        color:$color-light;
        font-size: 2.2rem;
        line-height: 2.6rem;
    }

}