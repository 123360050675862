h1, h2, h3, h4, h5, p {
  @include typography-base;
}
h1 {
  font-size: 1.9rem;
}

h2 {
  font-size: 1.7rem;
}

h3 {
  font-size: 1.3rem;
}

h4 {
  font-size: 1.12rem;
}

h5 {
  font-size: 1rem;
}

p {
  margin-bottom: 2rem;
  letter-spacing: 0;
}

a {
  color: $color-accent;
  text-decoration: none;
}