.home-wrap {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-image: url('../../assets/images/home-bg-01b-small.jpg');
  background-repeat:  no-repeat;
  background-position: center center;
 
  @include breakpoint(medium) {
    background-image: url('../../assets/images/home-bg-01b-medium.jpg');
  }

  @include breakpoint(large) {
    background-image: url('../../assets/images/home-bg-01b-large.jpg');
  }

  
} // END .home-wrap

.home-container {
  padding: 200px 0 0 0;

  .logo {
    width: 80%;
    max-width: 450px;
    display: block;
    margin: 0 auto 80px;
    
  }

  .button.wifi {
    .icon {
      margin-left: 7px;
      font-size: 200%;
      transform: rotate(90deg);
      top: 4px;
      margin-top: -7px;
    }
  }
}