.location-details-content-card {      
    // This class is used as a supplement to .location-details-card found in /components/_map-location-details-card.scss 
    height: 0;
    z-index: 100;
    transition: height 340ms ease-out;
    header {
        background: none;

        h1 {
            margin: 0 auto 20px;
            text-align: center;
            line-height: 1.2;
            max-width: 85%;
            padding-top: 35px;
          }

        p {
            max-width: 80%;
            margin: 0 auto 20px;
        }
        
    } // END header

    &.open {
        //height: 80vh;
        height: 73vh;
    }

    
    .location-hours-list {
        margin: 0 15px 30px;
        padding-bottom: 30px;
        height: 100%;
        @include breakpoint(large) {   
            height: auto;
        }

        .location-hours-row {
            padding: 6px 1px;
            text-align: left;
            display: grid;
            grid-template-columns: 41% 59%;
            align-items: center;  
            border-bottom: solid 1px lighten($color-base, 60%);

            .description {
                text-align: right;
            }

            &:last-child {
                border-bottom: none;
            }
        }        
    } // END .location-hours-list

    .location-notes-container {
        margin: 0 15px 30px;
        text-align: left;
        padding-bottom: 30px;
        height: 100%;

        @include breakpoint(large) {   
            height: auto;
        }
        
        b, strong {
            margin-top: 15px;
            display: block;
        }
    } // END .location-notes-container

    @include breakpoint(large) {              
        transition: left 340ms ease-out;
        height: 70vh;

        .overflow-y-scroll {
            height: 535px;
            overflow-y: auto;
        }

        &.open{
            left: 6rem;
            height: 70vh;
        }
    } // END large
      

}