.location-card-micro-details {
  @include blur-component-bg;
  @include card-radius;
  width: 100%;
  height: 0;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 99;
  transition: all .4s ease;
  -webkit-box-shadow: 0 0 21px 0 rgba(0,0,0,.13);
    box-shadow: 0 0 21px 0 rgba(0,0,0,.13);   

  &.open{
    height: 35vh;
    @include blur-component-bg;
  }

  .close {
    /*font-size: 2rem;
    padding: 15px;
    position: absolute;
    top: 0;
    right: 0;
    color: lighten($color-base, 55%);*/
    @include close-icon;
  }

  h2 {
    margin: 0 auto 15px;
    padding-top: 30px;
    text-align: center;
    line-height: 1.2;
    max-width: 85%;
  }

  .micro-details-grid {
    .left {
      width: 48%;
      display: inline-block;
      text-align: right;
    }
    .middle {
      width: 4%;
      display: inline-block;
      text-align: center;
    }
    .right {
      width: 48%;
      display: inline-block;
      text-align: left;
    }

    .button {
      margin-left: 0;
      margin-right: 0;
      text-transform: none;
    }
  } // END .status micro-details-grid

  .status {
    margin-bottom: 25px;
    .on {
      color: $color-on;
    }
    .off {
      color: $color-off;
    }
  }

} // END .location-card-micro-details

// SAFARI ONLY 
.browser-safari, .browser-mobile-safari {
  .location-card-micro-details{
    &.open {
      @include blur-component-bg;
    }
  
    .location-card-micro-details-inner {
      @include blur-component-bg(21, 0.89);
      @include card-radius;
      height: 100%;  
    }
    
  }  
}

// FIREFOX ONLY 
.browser-firefox {
  .location-card-micro-details{
    background-color: rgba(255, 255, 255, 0.95);
  }
}
