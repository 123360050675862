.wifi-status-widget {
    
    margin: 0 auto 25px;
    width: 100%;

    .status-text {
        letter-spacing: 0;        
        margin: 0 auto 10px;
        .icon {
            font-size: 2rem;
            top: 4px;
            position: relative;
            margin-right: 5px; 

            &.acp-wifi-side {
                transform: rotate(90deg);
            }

            &.acp-wifi-off {
                display: none;
            }
        }
    }

    .wifi-details {
        font-size: .89rem; 
        background: lighten($color-base, 72%);

        .label {
            display: inline-block;
            width: 35%;
            text-align: left;
            padding-right: 15px;
        }

        .description {
            display: inline-block;
            width: 59%;
            text-align: right;
            line-height: 1.1;

            .icon {
                margin-left: 6px;
                font-size: 0.8rem;
                opacity: .7;
            }
        }

        div {
            margin: 5px 15px;           
            padding: 10px 0;
        }

        div:first-child {
            border-bottom: solid 1px $color-light;
        }
    }

    &.on {
        .status-text {
            color: $color-on;
        }

        .wifi-details {
            background: lighten($color-on, 68%);
        }
    }

    &.off {
        .status-text {
            color: $color-off;

            .acp-wifi-side {
                display: none;
            }
            .acp-wifi-off {
                display: inline-block;
            }
        }

        .wifi-details {
            background: lighten($color-off, 63%);
        }
    }

}