.location-menu-item-row {
    width: 100%;
    padding: 20px 0;
    border-bottom: solid 1px lighten($color-base, 60%);
    text-align: left;
    display: inline-grid;
    grid-template-columns: 80px auto 50px;
    align-items: center;
    cursor: pointer;

    .item-column{
        &.icon, &.action {
            text-align: center;
        }

        &.icon {
            .icon-container {
                float: none;
                margin: 0 auto;
            }
        }

        &.action .acp {
            padding: 10px 15px;
            //color: lighten($color-base, 40%); 
            opacity: .6;
            
            &.acp-angle-right {
                font-size: 1.8rem;
            }
        }        
    }

    h3 {
        line-height: 1;
        margin: 0 0 5px 0;                
    }
    p {
        line-height: 1;
        margin: 0;
        font-size: .9rem;
    }
}  