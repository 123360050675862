// COLORS
$color-base: #38393A;
$color-primary: #677348;
$color-secondary: #9F8349;
$color-accent: #C26E47;
$color-light: #fff;
$color-off: #71322C;
$color-off-light: #71322C;
$color-on: #2A5D43;
$color-on-light: #2A5D43;

// TYPOGRAPHY
$font-family-base: Arial, Helvetica, sans-serif;

// FOOTER
$footer-nav-height: 85px;

