
html {
  background: #efe9e2;
  /*background: rgba(159,131,73, .05);
  background: -moz-linear-gradient(34deg, rgba(159,131,73,0.05) 8%, rgba(103,115,72,0.05) 100%);
  background: -webkit-linear-gradient(34deg, rgba(159,131,73,0.05) 8%, rgba(103,115,72,0.05) 100%);
  background: linear-gradient(34deg, rgba(159,131,73,0.05) 8%, rgba(103,115,72,0.05) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#9f8349",endColorstr="#677348",GradientType=1);*/
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#ffffff+0,efe9e2+84 */

  background: -moz-linear-gradient(45deg,  rgba(255,255,255,1) 0%, rgba(239,233,226,1) 70%); /* FF3.6-15 */
  background: -webkit-linear-gradient(45deg,  rgba(255,255,255,1) 0%,rgba(239,233,226,1) 70%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(-135deg,  rgba(255,255,255,1) 0%,rgba(239,233,226,1) 70%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#efe9e2',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
  background-attachment: fixed;

}

html, body, #root, .App {
  @include typography-base(0);
  margin: 0;
  height: 100vh;
  text-align: center;  
}

.overflow-y-scroll {
  //overflow-y: scroll;
  overflow-y: auto;
  width: 100%;
  height: 100%;
}

.hide {
  display: none !important;
}



