.MuiDrawer-paperAnchorRight {
  padding: 20px;
  width: 225px;

  li p {
    font-size: 1.2rem;
    font-weight: bold;
    letter-spacing: -1px;
  }
  
}