.button {
  @include typography-base(0);
  border-radius: 5px;
  background-color: $color-base;
  padding: 8px 17px 9px;
  margin: 0 .5rem .5rem;
  color: $color-light;
  text-decoration: none;
  text-transform: capitalize;
  font-weight: bold;
  display: inline-block;
  cursor: pointer;
  -webkit-appearance: none;
  vertical-align: middle;

  &.button-icon {
    .icon {
      font-size: 140%;
      display: inline-block;
      margin-left: 15px;
      top: 2px;
      position: relative;
    }
  }

  &.primary {
    background-color: $color-primary;
  }

  &.secondary {
    background-color: $color-secondary;
  }

  &.accent {
    background-color: $color-accent;
  }

  &.on {
    background-color: $color-on;
  }

  &.off {
    background-color: $color-off;
  }

  &.large {
    font-size: 1.35rem;
    padding: .6rem 1.4rem .7rem;

    .icon {
      margin-left: 15px;
    }
  }
} // END .button