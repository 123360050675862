.location-details-card {
    //@include blur-component-bg;
    @include card-radius;
    width: 100%;
    height: 0;
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 99;
    -webkit-box-shadow: 0 0 21px 0 rgba(0,0,0,.13);
    box-shadow: 0 0 21px 0 rgba(0,0,0,.13);
    background: $color-light;
    transition: height 340ms ease-out;

    &.open{
        //height: 82vh;
        height: 78vh;
    }

    .close {
        @include close-icon(1.4);
      }

    header {
        @include card-radius;
        padding-bottom: 15px;
        //background:$color-base;
        background: rgba(44,100,113, .13);
        background: -moz-linear-gradient(21deg, rgba(44,100,113,0.13) 10%, rgba(194,110,71,0.21) 95%);
        background: -webkit-linear-gradient(21deg, rgba(44,100,113,0.13) 10%, rgba(194,110,71,0.21) 95%);
        background: linear-gradient(21deg, rgba(44,100,113,0.13) 10%, rgba(194,110,71,0.21) 95%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#2c6471",endColorstr="#c26e47",GradientType=1);

        h1 {
            margin: 0 auto 20px;
            text-align: center;
            line-height: 1.2;
            max-width: 85%;
            padding-top: 35px;
          }

        p {
            max-width: 80%;
            margin: 0 auto 20px;

            a {
                color: $color-base;                
            }
        }

        > .button {
            margin-bottom: 15px; 
            text-transform: none;           
        }
    } // END header

    .location-card-menu-list {
        .location-menu-item-row {           
            // Root class .location-menu-item-row is defined in _map-locations-list.scss
            .item-column{                        
                .acp-paperclip-o {
                    font-size: 2rem;
                }
                .acp-heart-o {
                    font-size: 1.7rem;
                }
                .acp-heart {
                    font-size: 1.7rem;
                }
                .acp-browser {
                    font-size: 1.8rem;
                }
            }

            &:last-child {
                margin-bottom: 290px;
            }
            
        } // END location-menu-item-row    
    } // END location-card-menu-list

    @include breakpoint(large) {
        @include card-radius(12, 12, 12, 12);
        width: 375px;
        height: 70vh;
        top: 110px;
        bottom: auto;
        left: -500px;
        top: 210px;        
        transition: left 500ms ease;

        .overflow-y-scroll {
            height: 36vh;
            overflow-y: auto;
        }

        &.open{
            left: 3.125rem;
            height: 70vh;
        }

        .location-card-menu-list {
            .location-menu-item-row {                           
                &:last-child {
                    margin-bottom: 0;
                }                
            } // END location-menu-item-row    
        } // END location-card-menu-list
      } // END large
} // END location-details-card